import { Navbar, Row, Col } from 'react-bootstrap';
import {PeopleFill, PinMapFill, ChatSquareDotsFill, GearFill} from 'react-bootstrap-icons';
import { useLocation, Link } from 'react-router-dom';
import { isEqual } from 'lodash';

const footerIconActive = {
	width: '100%', 
	color: '#FF914D',
	marginBottom: '10px',
};
const footerIconStandby = {
	width: '100%',
	color: 'rgb(167 158 153)',
	marginBottom: '10px'
};
const footerRow = {
	width: '100%',
	maxWidth: '720px',
	minWidth: '200px',
	backgroundColor: 'white'
};

function NavBarFooter() {
	//URI 찾기
	const nowUri = useLocation();
	const nowPath = nowUri.pathname.split('/')[1];;

	return(
	  <Navbar fixed="bottom" variant="dark" className="text-center justify-content-center" style={{padding: "10px 0 10px 0", backgroundColor: 'white'}}>
	    <Row style={footerRow}>
	      <Col>
		<Link to="/friend">
		<PeopleFill style={isEqual(nowPath, 'friend')?footerIconActive:footerIconStandby} size={30}/>
		</Link>
	     </Col>
	      <Col>
		<Link to="/location">
		<PinMapFill style={isEqual(nowPath, 'location')?footerIconActive:footerIconStandby} size={30}/>
		</Link>
	      </Col>
	      <Col>
		<Link to="/chatting">
		<ChatSquareDotsFill style={isEqual(nowPath,'chatting')?footerIconActive:footerIconStandby} size={30}/>
		</Link>
	      </Col>
	      <Col>
		<Link to="/setting">
		<GearFill style={(nowPath === 'setting' || nowPath === 'notice')?footerIconActive:footerIconStandby} size={30}/>
		</Link>
	      </Col>
	    </Row>
	  </Navbar>
	);
}

export default NavBarFooter;
