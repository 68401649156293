import React, { useState, useEffect } from 'react';
import { Row, Col, Image, Ratio} from 'react-bootstrap';
import { Justify, X, PlayFill, BoxArrowInRight} from 'react-bootstrap-icons';
import { useNavigate, Link } from 'react-router-dom';
import {CSSTransition} from 'react-transition-group';
import OpenClass from '../location/OpenClass.js';
import ChangeRoom from './ChatMenu_ChangeRoom.js';
import ChangeRoomName from './ChatMenu_ChangeRoomName.js';
import axios from 'axios';


function ChatMenu({onGoBack, rid}) {

	const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
	const token = localStorage.getItem('token');

	const xImage = { 
	      position: 'absolute',
	      top: 0, 
	      right: 10, 
	      padding: '0px',
	      color: 'black',
	      zIndex: 1000, // 다른 요소 위에 위치하도록 z-index 설정
	      border: 0,
	      fontSize: '1.7rem'
	};
	const menuStyle={
		width: '100%',
		position: 'fixed',
		zIndex: -1,
		height: '100vh',
		backgroundColor: 'white',
		textAlign: 'left',
		paddingLeft: '10%',
		paddingRight: '10%',
		top:0
	};
	const menuSubject={
		fontWeight: 'bold',
		fontSize: '1rem',
		paddingTop: '1.2rem'
	};
	const chatRoomStyle = {
		textAlign: 'right',
		position: 'absolute',
		bottom: '2rem',
		right : 0,
		width: 'auto',
		backgroundColor: 'white',
		color: 'gray',
		paddingRight:'1.8rem'
	};
	const scrollContainerStyle = {height: '57vh', width: '100%', overflowY: 'scroll', overflowX: 'hidden', msOverflowStyle: 'none',  scrollbarWidth: 'none'};
	const navigate = useNavigate();
	const goBack = () => {
		if(onGoBack){
			console.log('GoBack : Executing onGoBack');
			onGoBack(); // 페이지 이동 전 실행할 함수
		}
		navigate(-1);
	};
	
	const [chatRoomUsers, setChatRoomUsers] = useState(null);
	const [myUid, setMyUid] = useState('');
	useEffect(() => {
		//const response = await axios.post(`${apiUrl}/ChatRoomUser`,{ myToken: token,  });
		const fetchData = async() => {
			try{
				const response = await axios.post(`${apiUrl}/ChatRoomUser`,{ myToken: token, rid: rid });
				console.log('RID 잘 도착했니 : ',response.data);
				setChatRoomUsers(response.data.roomUsers);
				setMyUid(response.data.myUid);
			}catch(error){
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	},[]);

	//메뉴 누르면 변경
	const [menuSetup, setMenuSetup] = useState(false);
	const clickMenu = () => {
		setMenuSetup(!menuSetup);
		if(menuSetup){
			setOpenClassSetup(false);
		}
	};
	//메뉴-2 OpenClass
	const [openClassSetup, setOpenClassSetup] = useState(false);
	const clickOpenClass = () => {
		setOpenClassSetup(!openClassSetup);
	};

	//메뉴-3 Change Room
	const [changeRoom, setChangeRoom] = useState(false);
	const clickChangeRoom = () => {
		setChangeRoom(!changeRoom);
	};

	//메뉴-3 Change Room Name
	const [changeRoomName, setChangeRoomName] = useState(false);
	const clickChangeRoomName = () => {
		setChangeRoomName(!changeRoomName);
	};

	return(
		<div>
		    <div onClick={clickMenu} style={xImage}>
			<CSSTransition
			    in={menuSetup}
			    timeout={300}
			    classNames='icon'
			>
			<span>{menuSetup ? <X /> : <Justify />}</span>
			</CSSTransition>
		    </div>
		{openClassSetup && (
		    <div>
			<OpenClass 
			    isCancelButtonVisible={openClassSetup} 
			    setIsCancelButtonVisible={setOpenClassSetup}
			    classRid={rid}
			/>
		    </div>
		)}
		{changeRoom && (
		    <div>
			<ChangeRoom rid={rid} changeRoom={changeRoom} setIsCancelButtonVisible={setChangeRoom}/>
		    </div>
		)}
		{changeRoomName && (
		    <div>
			<ChangeRoomName rid={rid} changeRoomName={changeRoomName} setIsCancelButtonVisible={setChangeRoomName}/>
		    </div>
		)}
		{menuSetup&&(<div style={menuStyle}>
		    <div style={{height:'3rem'}}></div>
			<hr/>
			<Row style={menuSubject} onClick={clickOpenClass}>
			    <Col style={{padding:0}}>모임개최</Col>
			    <Col style={{textAlign:'right', color:'gray'}}><PlayFill /></Col>
			</Row>
			<Row style={menuSubject} onClick={clickChangeRoom}>
			    <Col style={{padding:0}}>채팅방 변경</Col>
			    <Col style={{textAlign:'right', color:'gray'}}><PlayFill /></Col>
			</Row>
			<Row style={menuSubject} onClick={clickChangeRoomName}>
			    <Col style={{padding:0}}>채팅방 이름 변경</Col>
			    <Col style={{textAlign:'right', color:'gray'}}><PlayFill /></Col>
			</Row>

			<Row style={menuSubject}>
			    <Col style={{padding:0}}>참여자 목록</Col>
			</Row>
			<Row style={{paddingTop: '0'}}>
			    <div className="scroll-container" style={scrollContainerStyle}>
			    {chatRoomUsers.map((user, index) => (
				<Row style={{marginTop: '1.2rem'}}>
				    <Link 
				        to={user.uid === myUid ? '/profile' : `/friendProfile/${user.uid}`}
				        className='d-flex align-items-center' 
				        style={{display:'contents',textDecoration:'none', color:"black"}}
				    >

				    <Col style={{flex: '0 0 auto', width: '13%'}}>
					<Ratio aspectRatio="1x1">
					    <Image src={user.pimage} roundedCircle fluid />
					</Ratio>
				    </Col>
				    <Col xs={10}>
					<Row xs={12} style={{fontWeight:'bold', marginLeft: '10px'}}>{user.nname}</Row>
					<Row xs={12} style={{marginLeft: '10px', color: 'gray'}}>
				    		<div style={{overflow: 'hidden', textOverflow : 'ellipsis', whiteSpace: 'nowrap', width:'100%', padding: 0}}>{user.message}</div>
				    	</Row>
				    </Col>
				    </Link>
				</Row>
			    ))}
			    </div>
			</Row>
			<hr />
			<Row>
			    <Col style={chatRoomStyle} onClick={goBack}><b>채팅방 나가기 </b><BoxArrowInRight style={{fontSize:'1.7rem'}}/></Col>
			</Row>
		    </div>
		)}
		      <style jsx>{`
			.icon-enter {
				opacity: 0;
				transform: rotate(-180deg) scale(0.9);
			}
			.icon-enter-active {
		          	opacity: 1;
			        transform: scale(1);
		   	        transition: opacity 300ms, transform 300ms;
		        }
		        .icon-exit {
			        opacity: 0;
			        transform: scale(1);
		        }
		        .icon-exit-active {
			        opacity: 1;
			        transform: scale(0.9);
			        transition: opacity 300ms, transform 300ms;
		        }
		     `}</style>
		</div>
	);
}

export default ChatMenu;
