import React, { useState, useEffect } from 'react';
import {Row, Col, Ratio } from 'react-bootstrap';
import {PersonArmsUp} from 'react-bootstrap-icons';
import {Link} from 'react-router-dom';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
const token = localStorage.getItem('token');

//시간 변경하는 함수
const getTimeAgo = (timestamp) => {
  const now = new Date();
  const then = new Date(timestamp);
  const diff = Math.floor((now - then) / 1000); // difference in seconds
  if(timestamp){
      if (diff < 60) {
        return '방금 전';
      } else if (diff < 3600) {
        return `${Math.floor(diff / 60)}분 전`;
      } else if (diff < 86400) {
        return `${Math.floor(diff / 3600)}시간 전`;
      } else if (diff < 604800) {
        return `${Math.floor(diff / 86400)}일 전`;
      } else {
        return '오래 전';
      }
  }else{
      return 'ㅡ';
  }
};

//style 모음
const timeStyle={fontSize: '0.6rem',margin: 0,textAlign: 'right'};
const memberStyle={fontSize: '0.7rem', margin:0, textAlign: 'right'};
const ChatRoomName = {padding: 0,margin: 0, marginRight:'5px', fontWeight: 'bold'};
const displayDot = {overflow: 'hidden', textOverflow : 'ellipsis', whiteSpace: 'nowrap',display: 'block', color: '#787878'};
const messageStyle = {padding: 0};
const classOpenMessage = {padding: 0, color:'#787878'};
const noDataStyle = {width:'100%', textAlign:'center', padding: '1rem'};



function ChatLocation() {

	const apiUrl = process.env.REACT_APP_EXPRESS_ADDRESS;
	const token = localStorage.getItem('token');


	const [mainLo, setMainLo] = useState([]);
	const [subLo, setSubLo] = useState([]);

	useEffect(() => {
	    const fetchData = async () => {
		const response = await axios.post(`${apiUrl}/ChatMyLocationRoom`,{ myToken: token });
		setMainLo(response.data.mainloRooms);
		setSubLo(response.data.subloRooms);
		console.log('response: ',response);
		console.log('mainLo: ', response.data.mainloRooms);
		console.log('subLo: ', response.data.subloRooms);
	    };
	
	    fetchData();

	},[]);


	const mainLoInfo = mainLo.map((v, index) => (
	    <div key={index}>
		<Link style={{textDecoration: 'none', color: 'black'}} to={'/chatRoom/'+v.rid}>
		<Row style={{marginTop:'10px'}}>
		    <Col xs={2}>
			 <Ratio aspectRatio='1x1'>
			    <div style={{textAlign: 'center', backgroundColor: '#ffc107ad',  borderRadius: '6px',  display: 'flex',  alignItems: 'center',  justifyContent: 'center', fontWeight: 'bold', fontSize: '1.3rem'}}>{v.roomName[0]}</div>
			</Ratio>
		    </Col>
		    <Col xs={7}>
			<Row>
			    <span style={{padding: 0}}>
				{<span style={ChatRoomName}>{v.roomName}</span>}
				<span style={memberStyle}>{v.userCount}</span>
			    </span>
			</Row>
			<Row style={displayDot}>
			    {v.latestMessage.mType === 'c' 
				? <div style={classOpenMessage}><PersonArmsUp />모임 개최</div>
				: v.latestMessage.mType === 'i' ? (
				  <div style={classOpenMessage}>이미지</div>
				) : v.latestMessage.message ? (
				  v.latestMessage.message
				) : (
				  <div style={messageStyle}>메시지 없음</div>
				)}
			</Row>
		    </Col>
		    <Col xs={3} style={timeStyle}>{getTimeAgo(v.latestMessage.createAt)}</Col>
		</Row>
		</Link>
	    </div>
	));

	const subLoInfo = subLo.map((v, index) => (
	    <div key={index}>
		<Link style={{textDecoration: 'none', color: 'black'}} to={'/chatRoom/'+v.rid}>
		<Row style={{marginTop:'10px'}}>
		    <Col xs={2}>
			<Ratio aspectRatio='1x1'>
			    <div style={{textAlign: 'center',  backgroundColor: '#f67a07cc',  borderRadius: '6px',  display: 'flex',  alignItems: 'center',  justifyContent: 'center', fontWeight: 'bold', fontSize: '1.3rem'}}>{v.roomName[0]}</div>
			</Ratio>
		    </Col>
		    <Col xs={7}>
			<Row>
			    <span style={{padding: 0}}>
				{<span style={ChatRoomName}>{v.roomName}</span>}
				<span style={memberStyle}>{v.userCount}</span>
			    </span>
			</Row>
			<Row style={displayDot}>
			    {v.latestMessage.mType === 'c' 
				? <div style={classOpenMessage}><PersonArmsUp />모임 개최</div>
				: v.latestMessage.mType === 'i' ? (
				  <div style={classOpenMessage}>이미지</div>
				) : v.latestMessage.message ? (
				  v.latestMessage.message
				) : (
				  <div style={messageStyle}>메시지 없음</div>
				)}			</Row>
		    </Col>
		    <Col xs={3} style={timeStyle}>{getTimeAgo(v.latestMessage.createAt)}</Col>
		</Row>
		</Link>
	    </div>
	));

	return(
		<div style={{textAlign:'left', marginTop: '10px'}}>
		    <Row>
		    	<h5 style={{marginBottom:0}}><b>우리동네</b></h5>
		    </Row>
		    <div style={{backgroundColor:'white'}}>
		        {mainLoInfo && mainLoInfo.length > 0 ? mainLoInfo : 
				<div style={noDataStyle}>
				    위치 인증을 해주세요
				</div>
			}
		    </div>
		    <br />
		    <Row>
			<h5 style={{marginBottom:0}}><b>자주가는 동네</b></h5>
		    </Row>
			{subLoInfo && subLoInfo.length > 0 ? subLoInfo : 
				<div style={noDataStyle}>
				    위치 인증을 해주세요
				</div>
			}
		</div>
	);
}

export default ChatLocation;
