import {Container, Row, Col, Button, Image, Ratio} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {PinAngle} from 'react-bootstrap-icons';
import ClassView from './ClassView';
import {useState, useEffect} from 'react';

const fontSizeSmall={fontSize: '0.7rem', width: '100%', margin:'0', padding:'3px', textAlign: 'center'};
const fontSizeSmall2={fontSize: '0.6rem', width: '100%', marginRight:'0', backgroundColor: '#efefef', color:'black', fontWeight:'bold', border: '0'};
const buttonSpace0 = {margin: '0', padding: '0',fontSize: '0.6rem', width: '100%'};
const classBody = {backgroundColor: '#efefef', marginBottom:'2px', borderRadius: '6px',fontSize:'0.6rem', marginLeft: '0', paddingLeft: '4px', paddingRight: '4px', border: '0'};
const classAll = {textAlign: 'center', marginRight: '1px', marginLeft:'1px'};
const fontSmall = {fontSize:'0.6rem'};
const marginTop2 = {marginTop: '2px'};
const MyClassList = [
	['신림동 No.3', '달리기', '2024-04-30', '19:00', '5','5', '1', 'd68822f5d60372eeb8485ffcd8a9b68a'],
	['금호4가동 No.11', '독서모임!', '2024-05-14', '13:00', '3', '6','3', '0f1237033482f8fc2a818efdca981c5d'],
	['금호4가동 No.3', '커피한잔', '2024-05-14', '22:00', '1', '3','hobby', '0e99fa16aad57b04fd02a6196cb3b158'],
];

const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();

        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedHours = hours < 10 ? `0${hours}` : hours;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${formattedMonth}월 ${formattedDay}일 ${formattedHours}:${formattedMinutes}`;
};


function checkType(MyType){
        let category = 0;

        switch(MyType) {
                case '1': category = "/ClassAmity.png"; break;
                case '2': category = "/ClassExercise.png"; break;
                case '3': category = "/ClassHobby.png"; break;
                case '4': category = "/ClassEtc.png"; break;
                default: category = "/ClassEtc.png"; break;
        }   
        return category;
}




function OftenVisitLocationClass( {subClass, myUid} ) { 

        //최근 3개의 데이터 돌려서 추출하기
        const [subClassData, setSubClassData] = useState([]);

        useEffect(() => {
                console.log('Props in MyLocationClass :', subClass);
                if(subClass && subClass.length >0){
                        console.log('class Details sub!!!!!!!!!!!!!:', subClass);
                        setSubClassData(subClass);
                }   
                console.log('classDetails Out:', subClass);
        },[subClass]);


	const ViewSubClassList = subClassData.map((v)=> (
		<Col style={classAll}>
		    <div className="text-center" style={fontSmall}>
			{v[0]}
		    </div>
		    <Row>
			<Row>
			</Row>
			<Row style={classBody}>
			    {myUid === v.cOpenUser 
				    ? <div style={{position: 'relative'}}>
					<img
				    	    src='/crown.png'
				    	    alt='Crown Icon'
				    	    style={{position:'absolute', right:'5px', top:'5px', zIndex: 1, width: '15px', height: '15px'}}
				    	/>
				    </div>
				    : null
			    }
			    <Ratio aspectRatio="1x1" style={marginTop2}>
				<Image src={checkType(v.ctype)} fluid rounded />
			    </Ratio>
			    <div>{v.cTitle}</div>
			    <div>{formatDate(v.cOpenDate)}</div>
			    <div>참여인원: ({v.cNowUser.length+1}/{v.cTotalNum})</div>
			</Row>
			<Row style={buttonSpace0}>
			    <Button variant="secondary" style={fontSizeSmall2} onClick={() => handleViewClick(v.classId)}>
				내용보기
			    </Button>
			</Row>
		    </Row>
		</Col>
	));

	//######################클릭하면 보여주는 부분#################
        const [viewClassCheck, setViewClassCheck] = useState(false);
        const [viewClassId, setViewClassId] = useState(null);

        const handleViewClick = (classId) => {
            setViewClassId(classId);// viewClassId 상태를 업데이트합니다.
            setViewClassCheck(!viewClassCheck);
        };

	const handleCloseView = () => {
		 setViewClassCheck(!viewClassCheck);
	};
	//############################################################


	//데이터가 로드되지 않았을 때의 처리
	if(!subClass){
		return <p>Loading...</p>;
	}

	return(
	<div>
	<Row style={{marginTop: '20px'}}>
	    <Col xs={9}>
		<h5><b>자주가는 동네 모임</b></h5>
	    </Col>
	    <Col xs={3} style={{fontSize: '0.7rem'}}>
		{ViewSubClassList.length > 0 ? (
		<Link to='/myOftenLoClass'>
		    <Button variant="secondary" style={fontSizeSmall}>
			더보기
		    </Button>
		</Link>
		) : (
		    <Button variant="secondary" style={fontSizeSmall} disabled>
			더보기
		    </Button>
		)}
	    </Col>
	</Row>
	    <div>
		<Row style={fontSizeSmall}>
		    {ViewSubClassList.length > 0 ? ViewSubClassList : <p>모임이 없습니다</p>}
		</Row>
	    </div>
        {viewClassCheck && (
                <div>
                    <ClassView data={viewClassId} onCloseView={handleCloseView}/>
                </div>
        )}

	</div>
	);
}

export default OftenVisitLocationClass;
